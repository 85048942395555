.CustomerAuth {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__back {
    position: absolute;
    left: 15px;
    top: 20px;
  }

  &__logo {
    margin: 2em 0;
  }

  &__heading {
    font-size: 30px;
    line-height: 45px;
    font-weight: 100;
    font-family: serif;
    color: #909090;
    font-size: 3em;
    text-align: left;
    width: 80%;
    margin: 0 auto .5em auto;
    span {
      color: #000000;
      display: block;
    }
  }

  &__body {
    width: 100%;
    text-align: center;
  }

  &__credential, &__submit {
    display: block;
    margin: auto;
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
  }

  &__input {
    border: solid 1px #ddd;
    padding: 15px 20px;
    font-size: 18px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  &__submit {
    padding: 15px 0;
    border: none;
    color: #fff;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    background: #303030;
    box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
    border-radius: 8px;
    align-items: center;
  }

  .error {
    width: 80%;
    margin: auto;
    color: red;
    margin-bottom: 15px;
    position: relative;
    bottom: 10px;
  }
}
